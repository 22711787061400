import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { PictureInfo } from "../models/PictureInfo";

interface PictureButtonCardProps {
  pictureInfo: PictureInfo;
}

export const PictureButtonCard = (props: PictureButtonCardProps) => {
  const { pictureInfo } = props;
  const navigate = useNavigate();

  return (
    <Box display={"inline-grid"} sx={{ width: "100%" }}>
      <Button
        sx={{
          transform: "scale(1)",
          transition: "100ms transform ease",
          ":hover": {
            transform: "scale(1.05)",
            transition: "100ms transform ease",
          },
        }}
        onClick={() =>
          pictureInfo.route ? navigate(pictureInfo.route) : navigate("/")
        }
      >
        <img
          className="picture-img"
          src={pictureInfo.fileName}
          alt={pictureInfo.description}
        />
      </Button>

      <Typography variant="subtitle1">{pictureInfo.description}</Typography>
    </Box>
  );
};
