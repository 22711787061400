import { SvgIcon } from "@mui/material";
import { IconParams } from "../types/icon";

export const FacebookIcon = (params: IconParams) => {
  return (
    <SvgIcon
      sx={{ width: params.width, height: params.height }}
      color={params.themeColor}
      htmlColor={params.customColor}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M12 2.04c-5.5 0-10 4.49-10 10.02c0 5 3.66 9.15 8.44 9.9v-7H7.9v-2.9h2.54V9.85c0-2.51 1.49-3.89 3.78-3.89c1.09 0 2.23.19 2.23.19v2.47h-1.26c-1.24 0-1.63.77-1.63 1.56v1.88h2.78l-.45 2.9h-2.33v7a10 10 0 0 0 8.44-9.9c0-5.53-4.5-10.02-10-10.02Z"
      />
    </SvgIcon>
  );
};
