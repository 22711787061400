import { PictureInfo } from "../models/PictureInfo";

export const SZCZESLIWICKA_IMAGE_DICT: PictureInfo[] = [
  {
    fileName: "/images/gallery/szczesliwicka/szczesliwicka21.jpg",
    description: "Górka Szczęśliwicka",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/szczesliwicka/szczesliwicka20.jpg",
    description: "Górka Szczęśliwicka",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/szczesliwicka/szczesliwicka19.jpg",
    description: "Stok narciarski na Górce Szczęśliwickiej",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/szczesliwicka/szczesliwicka18.jpg",
    description: "Stok narciarski na Górce Szczęśliwickiej",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/szczesliwicka/szczesliwicka17.jpg",
    description: "Stok narciarski na Górce Szczęśliwickiej",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/szczesliwicka/szczesliwicka12.jpg",
    description: "Podejście na Górkę Szczęśliwicką",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/szczesliwicka/szczesliwicka13.jpg",
    description: "Schody pod szczytem Górki Szczęśliwickiej",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/szczesliwicka/szczesliwicka14.jpg",
    description: "Schody pod szczytem Górki Szczęśliwickiej",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/szczesliwicka/szczesliwicka15.jpg",
    description: "Szczyt Górki Szczęśliwickiej",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/szczesliwicka/szczesliwicka16.jpg",
    description: "Widok ze szczytu Górki Szczęśliwickiej",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/szczesliwicka/szczesliwicka03.jpg",
    description: "Widok z Górki Szczęśliwickiej",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/szczesliwicka/szczesliwicka04.jpg",
    description: "Widok z Górki Szczęśliwickiej w stronę Centrum",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/szczesliwicka/szczesliwicka11.jpg",
    description: "Park Szczęśliwicki",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/szczesliwicka/szczesliwicka10.jpg",
    description: "Park Szczęśliwicki",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/szczesliwicka/szczesliwicka09.jpg",
    description: "Park Szczęśliwicki",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/szczesliwicka/szczesliwicka08.jpg",
    description: "Park Szczęśliwicki",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/szczesliwicka/szczesliwicka07.jpg",
    description: "Park Szczęśliwicki",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/szczesliwicka/szczesliwicka06.jpg",
    description: "Park Szczęśliwicki",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/szczesliwicka/szczesliwicka05.jpg",
    description: "Park Szczęśliwicki",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/szczesliwicka/szczesliwicka02.jpg",
    description: "Górka Szczęśliwicka",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/szczesliwicka/szczesliwicka01.jpg",
    description: "Stok narciarski na Górce Szczęśliwickiej",
    date: "2022-05-03",
  },
];
