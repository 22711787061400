import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CONQUERORS } from "../data/ConquerorsData";

interface Column {
  id: "nr" | "name" | "place" | "date";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "nr", label: "Numer", minWidth: 40, align: "right" },
  { id: "name", label: "Imię i nazwisko", minWidth: 100 },
  {
    id: "place",
    label: "Miejscowość",
    minWidth: 100,
  },
  // {
  //   id: "date",
  //   label: "Data weryfikacji",
  //   minWidth: 80,
  // },
];

export const ZdobywcyPage = () => {
  const navigate = useNavigate();

  return (
    <Box maxWidth="1200px" width="100%">
      <Box sx={{ mt: 4 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/")}
          sx={{ mx: 0.5 }}
        >
          Korona Warszawy
        </Button>
      </Box>

      <Typography variant="h3" sx={{ mt: 3 }}>
        Lista Zdobywców
      </Typography>
      <Box sx={{ textAlign: "start", p: 1, mt: 3, width: "100%" }}>
        <Typography variant="body1" paragraph={true}>
          Oficjalna lista zweryfikowanych Zdobywców odznaki Korona Warszawy.
        </Typography>
      </Box>
      <Box sx={{ mt: 1, mb: 3 }}>
        <TableContainer sx={{ color: "white" }}>
          {/* <TableContainer sx={{ color: "white"}}>,  maxHeight: 560 }}> */}
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    sx={{ color: "white", background: "black" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {CONQUERORS.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.nr}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          sx={{ color: "white", fontSize: "1rem" }}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
