import { PictureInfo } from "../models/PictureInfo";

export const KAZURKA_IMAGE_DICT: PictureInfo[] = [
  {
    fileName: "/images/gallery/kazurka/kazurka15.jpg",
    description: "Wzgórze Trzech Szczytów od strony ulicy Stanisława Kazury",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/kazurka/kazurka14.jpg",
    description: "Wzgórze Trzech Szczytów od strony ulicy Stanisława Kazury",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/kazurka/kazurka13.jpg",
    description:
      "Park rowerowy na Wzgórzu Trzech Szczytów, w głębi Kościół pw. Ofarowania Pańskiego",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/kazurka/kazurka12.jpg",
    description:
      "Podejście na Wzgórze Trzech Szczytów od strony ulicy Stanisława Kazury",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/kazurka/kazurka11.jpg",
    description: "Najwyższy punkt Wzgórza Trzech Szczytów",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/kazurka/kazurka10.jpg",
    description: "Widok na Las Kabacki ze Wzgórza Trzech Szczytów",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/kazurka/kazurka09.jpg",
    description: '"Grań" Wzgórza Trzech Szczytów',
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/kazurka/kazurka08.jpg",
    description: '"Grań" Wzgórza Trzech Szczytów',
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/kazurka/kazurka07.jpg",
    description: "Inwestycja na jednym z wierzchołków Wzgórza Trzech Szczytów",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/kazurka/kazurka06.jpg",
    description:
      "Widok na Wzgórze Trzech Szczytów od strony ulicy Rotmistrza Witolda Pileckiego",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/kazurka/kazurka05.jpg",
    description: "Wzgórze Trzech Szczytów od strony ulicy Stanisława Kazury",
    date: "2022-05-03",
  },
  {
    fileName: "/images/gallery/kazurka/kazurka04.jpg",
    description: "Widok na Las Kabacki ze Wzgórza Trzech Szczytów",
    date: "2022-05-03",
  },
  {
    fileName: "/images/gallery/kazurka/kazurka03.jpg",
    description: '"Grań" Wzgórza Trzech Szczytów',
    date: "2022-05-03",
  },
  {
    fileName: "/images/gallery/kazurka/kazurka02.jpg",
    description: "Najwyższy punkt Wzgórza Trzech Szczytów",
    date: "2022-05-03",
  },
  {
    fileName: "/images/gallery/kazurka/kazurka01.jpg",
    description: "Wzgórze Trzech Szczytów",
    date: "2022-05-03",
  },
];
