import { PictureInfo } from "../models/PictureInfo";

export const MOCZYDLO_IMAGE_DICT: PictureInfo[] = [
  {
    fileName: "/images/gallery/moczydlo/moczydlo23.jpg",
    description:
      "Wschód słońca nad Warszawą, z widokiem na Kopiec Moczydłowski",
    date: "2023-03-06",
  },
  {
    fileName: "/images/gallery/moczydlo/moczydlo24.jpg",
    description: "Kopiec Moczydłowski o wschodzie słońca",
    date: "2023-03-06",
  },
  {
    fileName: "/images/gallery/moczydlo/moczydlo21.jpg",
    description: "Kopiec Moczydłowski od strony ulicy Czorsztyńskiej",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/moczydlo/moczydlo22.jpg",
    description: "U podnóża Kopca Moczydłowskiego",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/moczydlo/moczydlo20.jpg",
    description: "Kopiec Moczydłowski - schody",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/moczydlo/moczydlo18.jpg",
    description:
      "Kopiec Moczydłowski - widok ze szczytu w kierunku Centrum Warszawy",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/moczydlo/moczydlo17.jpg",
    description: "Lunety na szczycie Kopca Moczydłowskiego",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/moczydlo/moczydlo19.jpg",
    description:
      "Widok z Kopca Moczydłowskiego w kierunku południowo-zachodnim",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/moczydlo/moczydlo16.jpg",
    description: "Hamaki na stokach Kopca Moczydłowskiego",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/moczydlo/moczydlo15.jpg",
    description: "Kopiec Moczydłowski od strony zachodniej",
    date: "2023-03-04",
  },

  {
    fileName: "/images/gallery/moczydlo/moczydlo14.jpg",
    description: "Dawne glinianki w Parku Moczydło",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/moczydlo/moczydlo13.jpg",
    description: "Dawne glinianki w Parku Moczydło",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/moczydlo/moczydlo12.jpg",
    description: "Kopiec Moczydłowski widziany od strony Parku Moczydło",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/moczydlo/moczydlo11.jpg",
    description: "Kaczkomat w Parku Moczydło",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/moczydlo/moczydlo10.jpg",
    description: "Dawne glinianki w Parku Moczydło",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/moczydlo/moczydlo09.jpg",
    description: "Dawne glinianki w Parku Moczydło",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/moczydlo/moczydlo08.jpg",
    description: "Kaczki w Parku Moczydło",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/moczydlo/moczydlo07.jpg",
    description: "Dawne glinianki w Parku Moczydło",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/moczydlo/moczydlo06.jpg",
    description: "Kopiec Moczydłowski od strony ulicy Czorsztyńskiej",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/moczydlo/moczydlo05.jpg",
    description: "Strome zbocza Kopca Moczydłowskiego od ulicy Czorsztyńskiej",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/moczydlo/moczydlo04.jpg",
    description: "Widok na Kopiec Moczydłowski i Centrum Warszawy",
    date: "2023-03-01",
  },
  {
    fileName: "/images/gallery/moczydlo/moczydlo03.jpg",
    description: "Park Moczydło",
    date: "2022-05-15",
  },
  {
    fileName: "/images/gallery/moczydlo/moczydlo02.jpg",
    description:
      "Widok z Kopca Moczydłowskiego w kierunku ulicy Czorsztyńskiej",
    date: "2022-05-03",
  },
  {
    fileName: "/images/gallery/moczydlo/moczydlo01.jpg",
    description: "Widok z Kopca Moczydłowskiego w kierunku Parku Moczydło",
    date: "2022-05-03",
  },
];
