import "./App.css";
import { AppRoutes } from "./routes/AppRoutes";

export function App() {
  return (
    <div className="app">
      <header className="app-header">
        <AppRoutes />
      </header>
    </div>
  );
}

export default App;
