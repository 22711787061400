import { ReactNode } from "react";
import { BrowserRouter } from "react-router-dom";

interface AppProviderProps {
  children: ReactNode;
}
export const AppProviders = (props: AppProviderProps) => {
  const { children } = props;
  return <BrowserRouter>{children}</BrowserRouter>;
};
