import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GNOJNA_IMAGE_DICT } from "../dictionaries/GnojnaImageDict";
import { PictureGrid } from "../components/PictureGrid";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

export const GnojnaPage = () => {
  const navigate = useNavigate();

  return (
    <Box maxWidth="1200px" width="100%">
      <div>
        <Helmet prioritizeSeoTags>
          <title>Góra Gnojna</title>
          <meta name="description" content="Góra Gnojna" />
          <meta
            name="keywords"
            content="Góra Gnojna, Korona Warszawy, odznaka Korona Warszawy, Korona Gór Warszawy, odznaka turystyczna"
          />
          <link
            rel="canonical"
            href="https://koronawarszawy.waw.pl/goragnojna"
          />
        </Helmet>
      </div>
      <Box sx={{ mt: 4, textTransform: "capitalize" }}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => navigate("/kopacwila")}
        >
          <ArrowBack />
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/")}
          sx={{ mx: 0.5 }}
        >
          Korona Warszawy
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => navigate("/gorkaszczesliwicka")}
        >
          <ArrowForward />
        </Button>
      </Box>

      <Typography variant="h3" sx={{ mt: 3 }}>
        Góra Gnojna
      </Typography>
      <Box sx={{ textAlign: "start", p: 1, mt: 3, width: "100%" }}>
        <Typography variant="body1" paragraph={true}>
          Góra Gnojna ma wysokość 86.5 m n.p.m. i leży w dzielnicy Śródmieście,
          na warszawskiej Starówce.
        </Typography>
        {/* <Typography variant="body1" paragraph={true}>
          Więcej informacji już wkrótce...
        </Typography> */}
      </Box>
      <Box sx={{ mt: 1 }}>
        <PictureGrid
          imageDict={GNOJNA_IMAGE_DICT}
          useButtons={false}
          showHeader={true}
        />
      </Box>
    </Box>
  );
};
