import { Box, Typography } from "@mui/material";
import { PictureInfo } from "../models/PictureInfo";

interface PictureCardProps {
  pictureInfo: PictureInfo;
}

export const PictureCard = (props: PictureCardProps) => {
  const { pictureInfo } = props;
  return (
    <Box display={"inline-grid"} sx={{ width: "100%" }}>
      <img
        className="picture-img"
        src={pictureInfo.fileName}
        alt={pictureInfo.description}
      />
      <Typography variant="subtitle1">{pictureInfo.description}</Typography>
      {pictureInfo.date && (
        <Typography variant="caption" color={"GrayText"}>
          data zrobienia zdjęcia: {pictureInfo.date}
        </Typography>
      )}
    </Box>
  );
};
