import { PictureButtonCard } from "./PictureButtonCard";
import { Box, Grid, Typography } from "@mui/material";
import { PictureInfo } from "../models/PictureInfo";
import { PictureCard } from "./PictureCard";

interface PictureGridProps {
  imageDict: PictureInfo[];
  useButtons: Boolean;
  showHeader: Boolean;
}

export const PictureGrid = (props: PictureGridProps) => {
  const { imageDict, useButtons, showHeader } = props;

  return (
    <Box sx={{ mt: 1 }}>
      {showHeader && (
        <Typography variant="h4" sx={{ textAlign: "start" }} paragraph={true}>
          Galeria
        </Typography>
      )}

      <Grid container spacing={2}>
        {imageDict.map((p: PictureInfo) => {
          return (
            <Grid item md={4} sm={6} xs={12}>
              {useButtons ? (
                <PictureButtonCard pictureInfo={p} />
              ) : (
                <PictureCard pictureInfo={p} />
              )}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
