import { PictureInfo } from "../models/PictureInfo";

export const GNOJNA_IMAGE_DICT: PictureInfo[] = [
  {
    fileName: "/images/gallery/gnojna/gnojna02.jpg",
    description: "Widok na Górę Gnojną z Mostu Śląsko-Dąbrowskiego",
    date: "2023-03-01",
  },
  {
    fileName: "/images/gallery/gnojna/gnojna03.jpg",
    description: "Widok na Górę Gnojną z Mostu Śląsko-Dąbrowskiego",
    date: "2023-03-01",
  },
  {
    fileName: "/images/gallery/gnojna/gnojna04.jpg",
    description: "Widok na Górę Gnojną od strony Wisłostrady",
    date: "2023-03-01",
  },
  {
    fileName: "/images/gallery/gnojna/gnojna05.jpg",
    description: "Widok na Górę Gnojną od strony ulicy Bugaj",
    date: "2023-03-01",
  },
  {
    fileName: "/images/gallery/gnojna/gnojna06.jpg",
    description: "Platforma widokowa na Górze Gnojnej",
    date: "2023-03-01",
  },
  {
    fileName: "/images/gallery/gnojna/gnojna07.jpg",
    description: 'Pomnik "Siłacz" (autor: Stanisław Czarnowski)',
    date: "2023-03-01",
  },
  {
    fileName: "/images/gallery/gnojna/gnojna08.jpg",
    description: "Widok z Góry Gnojnej w kierunku Pragi",
    date: "2023-03-01",
  },
  {
    fileName: "/images/gallery/gnojna/gnojna09.jpg",
    description:
      "Widok z Góry Gnojnej w kierunku Mostu Świętokrzyskiego i Stadionu Narodowego",
    date: "2023-03-01",
  },
  {
    fileName: "/images/gallery/gnojna/gnojna10.jpg",
    description: "Widok z Góry Gnojnej w kierunku Pragi",
    date: "2023-03-01",
  },
  {
    fileName: "/images/gallery/gnojna/gnojna11.jpg",
    description: "Platforma widokowa na Górze Gnojnej",
    date: "2023-03-01",
  },
  {
    fileName: "/images/gallery/gnojna/gnojna12.jpg",
    description: "Ulica Steinkellera na stoku Góry Gnojnej",
    date: "2023-03-01",
  },
  {
    fileName: "/images/gallery/gnojna/gnojna13.jpg",
    description: "Platforma widokowa na Górze Gnojnej",
    date: "2022-05-03",
  },

  {
    fileName: "/images/gallery/gnojna/gnojna01.jpg",
    description: "Widok z Góry Gnojnej w kierunku południowo-wschodnim",
    date: "2022-05-03",
  },

  {
    fileName: "/images/gallery/gnojna/gnojna14.jpg",
    description: "Widok z Góry Gnojnej w kierunku wschodnim",
    date: "2022-05-03",
  },

  {
    fileName: "/images/gallery/gnojna/gnojna15.jpg",
    description: "Widok z Góry Gnojnej w kierunku północno-wschodnim",
    date: "2022-05-03",
  },
];
