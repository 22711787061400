import { Route, Routes } from "react-router-dom";
import { KopaCwilaPage } from "../pages/KopaCwilaPage";
import { HomePage } from "../pages/HomePage";
import { MoczydloPage } from "../pages/MoczydloPage";
import { SzczesliwickaPage } from "../pages/SzczesliwickaPage";
import { KazurkaPage } from "../pages/KazurkaPage";
import { GnojnaPage } from "../pages/GnojnaPage";
import { PowstaniePage } from "../pages/PowstaniePage";
import { ZdobywcyPage } from "../pages/ZdobywcyPage";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/gorkaszczesliwicka" element={<SzczesliwickaPage />} />
      <Route path="/gorkakazurka" element={<KazurkaPage />} />
      <Route path="/kopiecmoczydlowski" element={<MoczydloPage />} />
      <Route path="/kopiecpowstania" element={<PowstaniePage />} />
      <Route path="/kopacwila" element={<KopaCwilaPage />} />
      <Route path="/goragnojna" element={<GnojnaPage />} />
      <Route path="/zdobywcy" element={<ZdobywcyPage />} />
    </Routes>
  );
};
