import { Box, Button, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { PictureGrid } from "../components/PictureGrid";
import { MOCZYDLO_IMAGE_DICT } from "../dictionaries/MoczydloImageDict";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

export const MoczydloPage = () => {
  const navigate = useNavigate();

  return (
    <Box maxWidth="1200px" width="100%">
      <Helmet>
        <title>Kopiec Moczydłowski</title>
        <meta name="description" content="Kopiec Moczydłowski" />
        <meta
          name="keywords"
          content="Kopiec Moczydłowski, Korona Warszawy, odznaka Korona Warszawy, Korona Gór Warszawy, odznaka turystyczna"
        />
        <link
          rel="canonical"
          href="https://koronawarszawy.waw.pl/kopiecmoczydlowski"
        />
      </Helmet>
      <Box sx={{ mt: 4 }}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => navigate("/gorkakazurka")}
        >
          <ArrowBack />
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/")}
          sx={{ mx: 0.5 }}
        >
          Korona Warszawy
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => navigate("/kopiecpowstania")}
        >
          <ArrowForward />
        </Button>
      </Box>

      <Typography variant="h3" sx={{ mt: 3 }}>
        Kopiec Moczydłowski
      </Typography>
      <Box sx={{ textAlign: "start", p: 1, mt: 3, width: "100%" }}>
        <Typography variant="body1" paragraph={true}>
          Kopiec Moczydłowski ma wysokość 130 m n.p.m. i leży w dzielnicy Wola.
        </Typography>
        {/* <Typography variant="body1" paragraph={true}>
          Więcej informacji już wkrótce...
        </Typography> */}
      </Box>
      <Box sx={{ mt: 1 }}>
        <PictureGrid
          imageDict={MOCZYDLO_IMAGE_DICT}
          useButtons={false}
          showHeader={true}
        />
      </Box>
    </Box>
  );
};
