import { Box, Button, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { PictureGrid } from "../components/PictureGrid";
import { KAZURKA_IMAGE_DICT } from "../dictionaries/KazurkaImageDict";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

export const KazurkaPage = () => {
  const navigate = useNavigate();

  return (
    <Box maxWidth="1200px" width="100%">
      <Helmet>
        <title>Wzgórze Trzech Szczytów (Kazurka)</title>
        <meta name="description" content="Wzgórze Trzech Szczytów (Kazurka)" />
        <meta
          name="keywords"
          content="Wzgórze Trzech Szczytów, Górka Kazurka, Kazurka, Kazurówka, Kazoorka, Góra Kazury, Monte Kazuro, Góra Latawcowa, Korona Warszawy, odznaka Korona Warszawy, Korona Gór Warszawy, odznaka turystyczna"
        />
        <link
          rel="canonical"
          href="https://koronawarszawy.waw.pl/gorkakazurka"
        />
      </Helmet>
      <Box sx={{ mt: 4 }}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => navigate("/gorkaszczesliwicka")}
        >
          <ArrowBack />
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/")}
          sx={{ mx: 0.5 }}
        >
          Korona Warszawy
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => navigate("/kopiecmoczydlowski")}
        >
          <ArrowForward />
        </Button>
      </Box>

      <Typography variant="h3" sx={{ mt: 3 }}>
        Wzgórze Trzech Szczytów
      </Typography>
      <Box sx={{ textAlign: "start", p: 1, mt: 3, width: "100%" }}>
        <Typography variant="body1" paragraph={true}>
          Wzgórze Trzech Szczytów (inne nazwy: Górka Kazurka, Kazurka,
          Kazurówka, Kazoorka, Góra Kazury, Monte Kazuro, Góra Latawcowa) ma
          wysokość 133,9 m n.p.m. i leży w dzielnicy Ursynów.
        </Typography>
        {/* <Typography variant="body1" paragraph={true}>
          Więcej informacji już wkrótce...
        </Typography> */}
      </Box>

      <Box sx={{ mt: 1 }}>
        <PictureGrid
          imageDict={KAZURKA_IMAGE_DICT}
          useButtons={false}
          showHeader={true}
        />
      </Box>
    </Box>
  );
};
