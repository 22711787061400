import { PictureInfo } from "../models/PictureInfo";

export const KOPACWILA_IMAGE_DICT: PictureInfo[] = [
  {
    fileName: "/images/gallery/kopa_cwila/kopa_cwila15.jpg",
    description: "Widok na Kopę Cwila od strony Doliny Służewieckiej",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/kopa_cwila/kopa_cwila14.jpg",
    description: "Widok na Kopę Cwila od strony północno-zachodniej",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/kopa_cwila/kopa_cwila13.jpg",
    description:
      "Kopa Cwila - schody na szczyt. Można się poczuć jak w Bieszczadach :)",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/kopa_cwila/kopa_cwila12.jpg",
    description: "Szczyt Kopy Cwila",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/kopa_cwila/kopa_cwila11.jpg",
    description: "Kopa Cwila - widok ze szczytu w kierunku północno-zachodnim",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/kopa_cwila/kopa_cwila10.jpg",
    description: "Kopa Cwila - widok ze szczytu w kierunku południowym",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/kopa_cwila/kopa_cwila09.jpg",
    description: "Widok na Kopę Cwila od strony Parku Romana Kozłowskiego",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/kopa_cwila/kopa_cwila08.jpg",
    description: "Widok na Kopę Cwila od strony Parku Romana Kozłowskiego",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/kopa_cwila/kopa_cwila07.jpg",
    description: "Widok na Kopę Cwila od strony północno-zachodniej",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/kopa_cwila/kopa_cwila06.jpg",
    description: "Projektowany wygląd Parku Romana Kozłowskiego",
    date: "2023-03-04",
  },
  {
    fileName: "/images/gallery/kopa_cwila/kopa_cwila05.jpg",
    description:
      "Pomnik przyrody - głaz narzutowy u wejścia do Parku Romana Kozłowskiego",
    date: "2022-05-01",
  },
  {
    fileName: "/images/gallery/kopa_cwila/kopa_cwila04.jpg",
    description: "Widok na Kopę Cwila od strony Parku Romana Kozłowskiego",
    date: "2022-05-01",
  },
  {
    fileName: "/images/gallery/kopa_cwila/kopa_cwila03.jpg",
    description:
      "Kopa Cwila - widok ze szczytu w kierunku południowo-zachodnim",
    date: "2022-05-01",
  },
  {
    fileName: "/images/gallery/kopa_cwila/kopa_cwila02.jpg",
    description: "Kopa Cwila - widok ze szczytu w kierunku północno-zachodnim",
    date: "2022-05-01",
  },
  {
    fileName: "/images/gallery/kopa_cwila/kopa_cwila01.jpg",
    description: "Kopa Cwila - widok ze szczytu w kierunku północno-wschodnim",
    date: "2022-05-01",
  },
];
