import { PictureInfo } from "../models/PictureInfo";

export const POWSTANIE_IMAGE_DICT: PictureInfo[] = [
  {
    fileName: "/images/gallery/powstanie/powstanie05.jpg",
    description:
      "Budowa nowej platformy na szczycie Kopca Powstania Warszawskiego",
    date: "2022-05-03",
  },
  {
    fileName: "/images/gallery/powstanie/powstanie06.jpg",
    description:
      "Budowa nowej platformy na szczycie Kopca Powstania Warszawskiego",
    date: "2022-05-03",
  },
  {
    fileName: "/images/gallery/powstanie/powstanie03.jpg",
    description:
      "Widok z Kopca Powstania Warszawskiego w kierunku południowo-wschodnim",
    date: "2022-05-03",
  },
  {
    fileName: "/images/gallery/powstanie/powstanie04.jpg",
    description: "Widok z Kopca Powstania Warszawskiego w kierunku południowym",
    date: "2022-05-03",
  },
  {
    fileName: "/images/gallery/powstanie/powstanie02.jpg",
    description:
      "Widok z Kopca Powstania Warszawskiego w kierunku południowo-zachodnim",
    date: "2022-05-03",
  },
  {
    fileName: "/images/gallery/powstanie/powstanie01.jpg",
    description: "Kopiec Powstania Warszawskiego w trakcie przebudowy",
    date: "2022-05-03",
  },
];
